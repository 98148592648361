import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const Clients = lazy(() => import('../pages/clients'));
export const CLIENTS_PATHS = {
    root: `/clients`,
};
export const ClientsRoutes = () => {
    const { tenant: { businesses }, } = useConfigurationContext();
    const { root } = CLIENTS_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: businesses.enabled ? _jsx(Clients, {}) : _jsx(FeatureNotEnabled, { feature: "Clients" }) }, root),
    ];
};
