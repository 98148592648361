import { downloadFile as legacyDownloadFile } from 'legacy/_helpers/download';
const fromUrl = (url, fileName) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
};
// This function wraps the legacy downloadFile function
const fromData = ({ data, filename, mimeType, }) => {
    legacyDownloadFile(data, filename, mimeType);
};
export const download = {
    fromUrl,
    fromData,
};
