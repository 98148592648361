import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
const Profile = lazy(() => import('../pages/profile'));
export const USERS_PATHS = {
    profile: '/users/profile',
};
export const UsersRoutes = () => {
    const { profile } = USERS_PATHS;
    return [
        _jsx(Route, { exact: true, path: profile, children: _jsx(Profile, {}) }, profile),
    ];
};
