// * NOTE *
// Update this config when you want to add new params or values
const searchParamConfig = {
    'add-document': ['true', 'false'],
    admin: ['true', 'false'],
    edit: ['true', 'false'],
    emailAddress: [],
    print: ['true', 'false'],
    quoteCreated: ['true', 'false'],
    tab: [],
    validate: ['true', 'false'],
    verificationToken: [],
    'integration-plaid-accounts': [],
    view: [
        'brokers',
        'business-information',
        'notes',
        'quotes',
        'risk-and-fraud',
        'sign-documents',
        'status',
        'underwriting',
        'uploaded-documents',
    ],
    welcome: ['true', 'false'],
};
// Type guard function
export const isValidSearchParam = (key, value) => {
    const keyValues = searchParamConfig[key];
    // If specific valid values are defined, check against them
    if (keyValues?.length > 0) {
        return keyValues.includes(value);
    }
    // If the array is empty, allow any string
    return typeof value === 'string';
};
