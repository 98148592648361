import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Footer as LegacyFooter } from 'legacy/_components/Footer/Footer';
import { Header as LegacyHeader } from 'legacy/_components/header/Header';
import { AppShell, Stack } from 'xen-ui';
import { useAuthentication, useFeatureFlags } from 'xen/hooks';
import { useAppShellNavBarContext } from 'xen/providers';
import { BurgerAndLogo } from './burger-and-logo';
import { Footer } from './footer';
import { NavBar } from './nav-bar';
/**
 * New drawer nav app layout.
 * When `xen_layout` feature is NOT enabled and user is not logged in, use legacy layout.
 * When `xen_layout` feature IS enabled, and user is not logged in, use no layout.
 * Otherwise, use new layout.
 */
export const AppLayout = ({ children }) => {
    const { isLoggedIn } = useAuthentication();
    const { xenLayout } = useFeatureFlags();
    const { isMobile } = useAppShellNavBarContext();
    const XenLayout = isMobile ? MobileLayout : DesktopLayout;
    // Legacy layout when xen_layout is disabled and user is not logged in
    if (!xenLayout && !isLoggedIn)
        return (_jsxs(_Fragment, { children: [_jsx(LegacyHeader, {}), children, _jsx(LegacyFooter, {})] }));
    // No shared layout for unauthenticated users
    if (!isLoggedIn)
        return _jsx(_Fragment, { children: children });
    // Xen layout for everyone else
    return _jsx(XenLayout, { children: children });
};
// Private --------------------------------------------------------------------
/**
 * MOBILE
 * render the burger and logo in a header across the top of the screen
 * and all navigation items in a full-width, show-hide navbar
 */
const MobileLayout = ({ children }) => {
    const { isNavHidden, navHeight } = useAppShellNavBarContext();
    return (_jsxs(AppShell, { header: { height: navHeight }, navbar: {
            breakpoint: 'xs',
            width: '100%',
            collapsed: { mobile: isNavHidden }, // navbar can be hidden on mobile
        }, children: [_jsx(AppShell.Header, { children: _jsx(BurgerAndLogo, {}) }), _jsx(AppShell.Navbar, { id: "navbar", children: _jsx(NavBar, {}) }), _jsx(AppShell.Main, { maw: "100vw", mih: "100vh", style: { overflow: 'scroll' }, children: _jsxs(Stack, { justify: "space-between", mih: "inherit", children: [children, _jsx(Footer, {})] }) })] }));
};
/**
 * DESKTOP
 * render the burger and logo at the top of the navbar
 * and all navigation items below in a max-width, expand-collapse navbar
 */
const DesktopLayout = ({ children }) => {
    const { navWidth } = useAppShellNavBarContext();
    return (_jsxs(AppShell, { navbar: {
            breakpoint: 'xs',
            width: navWidth, // width is dynamic based on expand/collapse, never totally hidden
        }, children: [_jsxs(AppShell.Navbar, { id: "navbar", children: [_jsx(AppShell.Section, { "aria-label": "Navigation Header", children: _jsx(BurgerAndLogo, {}) }), _jsx(NavBar, {})] }), _jsx(AppShell.Main, { h: "100vh", maw: "100vw", style: { overflow: 'scroll' }, children: _jsxs(Stack, { justify: "space-between", mih: "inherit", children: [children, _jsx(Footer, {})] }) })] }));
};
