import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
const Notifications = lazy(() => import('../pages/notifications'));
export const NOTIFICATIONS_PATHS = {
    root: '/notifications',
};
export const NotificationsRoutes = () => {
    const { root } = NOTIFICATIONS_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(Notifications, {}) }, root),
    ];
};
