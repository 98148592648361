import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const FundingRequests = lazy(() => import('../pages/funding-requests'));
export const FUNDING_REQUESTS_PATHS = {
    root: '/funding-requests',
};
export const FundingRequestsRoutes = () => {
    const { tenant: { factoring }, } = useConfigurationContext();
    const { root } = FUNDING_REQUESTS_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: factoring.enabled ? _jsx(FundingRequests, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, root),
    ];
};
