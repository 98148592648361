import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
const SigningDocuments = lazy(() => import('../pages/signing-documents'));
export const SIGNING_DOCUMENTS_PATHS = {
    root: '/signing-documents',
};
export const SigningDocumentsRoutes = () => {
    const { productEsigning } = useFeatureFlags();
    const { root } = SIGNING_DOCUMENTS_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: () => {
                if (!productEsigning)
                    return _jsx(FeatureNotEnabled, { feature: "E-Signing" });
                return (_jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(SigningDocuments, {}) }));
            } }, root),
    ];
};
