import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const InvoiceScheduleEdit = lazy(() => import('../pages/invoice-schedule-edit'));
const InvoiceScheduleNew = lazy(() => import('../pages/invoice-schedule-new'));
const InvoiceSchedules = lazy(() => import('../pages/invoice-schedules'));
export const INVOICE_SCHEDULES_PATHS = {
    root: '/invoice-schedules',
    newInvoice: '/invoice-schedules/new',
    editInvoice: '/invoice-schedules/:scheduleId/edit',
};
export const InvoiceSchedulesRoutes = () => {
    const { tenant: { factoring }, } = useConfigurationContext();
    const { editInvoice, newInvoice, root } = INVOICE_SCHEDULES_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: _jsx(InvoiceSchedules, {}) }, root),
        _jsx(Route, { exact: true, path: newInvoice, children: factoring.enabled ? _jsx(InvoiceScheduleNew, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, newInvoice),
        _jsx(Route, { exact: true, path: editInvoice, children: factoring.enabled ? _jsx(InvoiceScheduleEdit, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, editInvoice),
    ];
};
