import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const LenderHome = lazy(() => import('../pages/lender-home'));
const ClientHome = lazy(() => import('../pages/client-home'));
const ProspectHome = lazy(() => import('../pages/prospect-home'));
export const HOME_PATHS = {
    root: '/',
    lender: '/lender-home',
    client: '/client-home',
    prospect: '/prospect-home',
};
export const HomeRoutes = () => {
    const { tenant: { factoring, businesses }, } = useConfigurationContext();
    const { client, lender, prospect } = HOME_PATHS;
    return [
        _jsx(Route, { exact: true, path: lender, children: () => {
                if (!factoring.enabled)
                    return _jsx(FeatureNotEnabled, { feature: "Factoring" });
                return (_jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(LenderHome, {}) }));
            } }, lender),
        _jsx(Route, { exact: true, path: client, children: () => {
                if (!businesses.enabled)
                    return _jsx(FeatureNotEnabled, { feature: "Clients" });
                return (_jsx(RestrictedAccess, { userTypes: ['client'], children: _jsx(ClientHome, {}) }));
            } }, client),
        _jsx(Route, { exact: true, path: prospect, children: () => {
                return (_jsx(RestrictedAccess, { userTypes: ['client', 'prospect'], children: _jsx(ProspectHome, {}) }));
            } }, prospect),
    ];
};
