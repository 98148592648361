import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChangePassword } from 'legacy/_components/modals/changePassword';
import { Heading, Stack, Text } from 'xen-ui';
import { Page, PageTitle } from 'xen/components';
import { useUserContext } from 'xen/providers';
const Profile = () => {
    const user = useUserContext();
    return (_jsxs(Page, { children: [_jsx(PageTitle, { title: "User profile" }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Info" }), _jsxs(Stack, { gap: "xs", children: [_jsxs(Text, { children: [_jsx("b", { children: "Name" }), ": ", user.name] }), _jsxs(Text, { children: [_jsx("b", { children: "Email" }), ": ", user.emailAddress] })] })] }), _jsxs(Stack, { gap: "sm", children: [_jsx(Heading, { level: 2, children: "Security" }), _jsx(ChangePassword, { email: user.emailAddress, user_id: user.id })] })] }));
};
export default Profile;
