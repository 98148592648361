import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const InvoiceSettlements = lazy(() => import('../pages/invoice-settlements'));
const InvoiceSettlementEdit = lazy(() => import('../pages/invoice-settlement-edit'));
export const INVOICE_SETTLEMENTS_PATHS = {
    root: '/invoice-settlements',
    editSettlement: '/invoice-settlements/:settlementId/edit',
};
export const InvoiceSettlementsRoutes = () => {
    const { tenant: { factoring }, } = useConfigurationContext();
    const { editSettlement, root } = INVOICE_SETTLEMENTS_PATHS;
    return [
        _jsx(Route, { exact: true, path: root, children: factoring.enabled ? _jsx(InvoiceSettlements, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, root),
        _jsx(Route, { path: editSettlement, children: factoring.enabled ? _jsx(InvoiceSettlementEdit, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, editSettlement),
    ];
};
