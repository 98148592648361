import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
const EnrollmentSignUp = lazy(() => import('../pages/enrollment-sign-up'));
const EnrollmentVerify = lazy(() => import('../pages/enrollment-verify'));
export const ENROLLMENTS_PATHS = {
    signUp: '/enrollments/sign-up',
    verify: '/enrollments/verify',
};
export const EnrollmentsRoutes = () => {
    const { signUp, verify } = ENROLLMENTS_PATHS;
    const { tenantEnrollment } = useFeatureFlags();
    return [
        _jsx(Route, { exact: true, path: signUp, children: () => {
                if (!tenantEnrollment)
                    return _jsx(FeatureNotEnabled, { feature: "Tenant Enrollment" });
                return _jsx(EnrollmentSignUp, {});
            } }, signUp),
        _jsx(Route, { exact: true, path: verify, children: () => {
                if (!tenantEnrollment)
                    return _jsx(FeatureNotEnabled, { feature: "Tenant Enrollment" });
                return _jsx(EnrollmentVerify, {});
            } }, verify),
    ];
};
