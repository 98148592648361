import { paramsSerializer } from 'xen/api';
import { isValidSearchParam } from 'xen/types';
/**
 * Dynamically construct a valid internal link path (based on optional
 * `AllowedPath` type passed to funtion) where dynamic URL params (e.g.
 * `:someId`) are replaced and search params are appended.
 *
 * ```ts
 * createInternalLinkUrl({
 *  to: '/debtors/:debtorId/invoices/:invoiceId/users/:userId',
 *  urlParams: ['123', '456', '789'],
 *  searchParams: { sort: 'date', state: ['closed', 'purchased'] },
 * })
 * // => '/debtors/123/invoices/456/users/789?sort=date&state%5B%5D=closed&state%5B%5D=purchased'
 * ```
 *
 * To constrain the valid internal link paths that can be passed to the function,
 * you can pass a custom type to the function like so:
 * ```ts
 * type AllowedPath = '/foo' | '/bar'
 * createInternalLinkUrl<AllowedPath>({
 *   to: '/foo', // Only '/foo' or '/bar' are allowed
 * })
 * ```
 *
 * NOTE: This only supports a maximum of 3 dynamic URL params. If you need more,
 * update the `InternalLinkUrlProps` type in `xen/types/link.ts` or manually
 * construct your URL.
 */
export const createInternalLinkUrl = ({ to, urlParams, searchParams = {}, }) => {
    const dynamicParts = to.split('/').filter((part) => part.startsWith(':'));
    if (dynamicParts.length > 3 || (urlParams && urlParams.length > 3)) {
        throw new Error(`Maximum of 3 dynamic URL params allowed`);
    }
    let dynamicIndex = 0;
    const replacedURL = to.replace(/:[^/]+/g, () => {
        const param = urlParams ? String(urlParams[dynamicIndex]) : '';
        dynamicIndex++;
        return param || '';
    });
    const urlSearchParams = paramsSerializer(searchParams);
    const urlWithSearchParams = [replacedURL, urlSearchParams].filter(Boolean).join('?');
    return urlWithSearchParams;
};
export const getS3BucketForEnv = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[1];
    if (subdomain === 'localhost') {
        return 'development';
    }
    if (subdomain === 'development') {
        return 'development';
    }
    if (subdomain === 'staging') {
        return 'staging';
    }
    return 'prod';
};
export const getTenantFromUrl = () => {
    const url = window.location.origin;
    const regex = /^https?:\/\/([^/.]+)/;
    const match = url.match(regex) ?? [];
    const tenant = match[1];
    if (tenant === undefined || tenant.startsWith('localhost'))
        return '';
    return tenant;
};
export const searchParam = {
    get: (key, fallback = '') => {
        const value = new URLSearchParams(window.location.search).get(key);
        // If no value is found, return the fallback
        if (!value)
            return fallback;
        // If the value is valid for the given key, return it
        if (isValidSearchParam(key, value))
            return value;
        // Otherwise, return the fallback
        return fallback;
    },
    remove: (key) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key);
        const newUrl = url.toString();
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    },
    set: (key, value) => {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        const newUrl = url.toString();
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    },
    setDefault: (key, defaultValue) => {
        const currentValue = searchParam.get(key);
        const currentOrValidFallbackValue = searchParam.get(key, defaultValue);
        // If current value is valid, don't change it
        if (isValidSearchParam(key, currentValue))
            return currentValue;
        // Otherwise, set it to the default value
        searchParam.set(key, currentOrValidFallbackValue);
        return currentOrValidFallbackValue;
    },
};
