import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIcon, Flex, Group, Image, Tooltip, XenIcon } from 'xen-ui';
import XenLogo from 'xen/assets/xen-logo.svg';
import { useFeatureFlags } from 'xen/hooks';
import { useAppShellNavBarContext, useConfigurationContext, useTenantContext } from 'xen/providers';
import { TextLink } from '../../link';
export const BurgerAndLogo = () => {
    const { isMobile } = useAppShellNavBarContext();
    const BurgerAndLogoComponent = isMobile ? MobileBurgerAndLogo : DesktopBurgerAndLogo;
    return _jsx(BurgerAndLogoComponent, {});
};
export const PoweredByXen = () => {
    const { xenPremium } = useFeatureFlags();
    // Eventually this should check a configuration setting specific to this xen branding
    if (xenPremium)
        return null;
    return (_jsxs(_Fragment, { children: ["Powered by ", _jsx(XenLogo, { style: { height: '0.8em', width: 'auto' } })] }));
};
export const Logo = ({ ...props }) => {
    const tenant = useTenantContext();
    const configuration = useConfigurationContext();
    return (_jsx(Image, { fit: "contain", flex: 1, h: "100%", ...props, alt: tenant.organizationName, src: configuration.tenant.branding.images.logo }));
};
// Private --------------------------------------------------------------------
const MobileBurgerAndLogo = () => {
    const { isNavHidden, navHeight, toggleNavVisibility } = useAppShellNavBarContext();
    return (_jsxs(Wrapper, { children: [_jsx(BurgerToggleButton, { isExpanded: !isNavHidden, onClick: toggleNavVisibility }), _jsxs(TextLink, { style: {
                    alignItems: 'center',
                    color: 'inherit',
                    display: 'flex',
                    gap: '0.5rem',
                    height: `calc(${navHeight} - 1rem)`,
                    justifyContent: 'space-between',
                    width: '100%',
                }, to: "/", underline: "never", children: [_jsx(Logo, { style: { objectPosition: 'left' } }), _jsx(Flex, { align: "center", component: "span", fz: "0.5625em", gap: "0.125rem", children: _jsx(PoweredByXen, {}) })] })] }));
};
const DesktopBurgerAndLogo = () => {
    const { isNavCollapsed, toggleNavWidth } = useAppShellNavBarContext();
    return (_jsxs(Wrapper, { children: [_jsx(BurgerToggleButton, { isExpanded: !isNavCollapsed, onClick: toggleNavWidth }), !isNavCollapsed && (_jsx(TextLink, { style: {
                    alignItems: 'center',
                    color: 'inherit',
                    display: 'flex',
                    height: '100%',
                    position: 'relative',
                }, to: "/", underline: "never", children: _jsxs(Flex, { align: "center", component: "span", style: { position: 'relative' }, children: [_jsx(Logo, {}), _jsx(Flex, { align: "center", component: "span", fz: "0.5625em", gap: "0.125rem", style: {
                                bottom: '-0.125rem',
                                lineHeight: '0.75rem',
                                position: 'absolute',
                                right: '0',
                                transform: 'translateY(100%)',
                            }, children: _jsx(PoweredByXen, {}) })] }) }))] }));
};
const BurgerToggleButton = ({ isExpanded, onClick }) => {
    const label = 'Toggle navigation';
    const icon = (_jsx(ActionIcon, { "aria-controls": "navbar", "aria-expanded": isExpanded, "aria-haspopup": true, "aria-label": label, color: "text", onClick: onClick, variant: "transparent", children: _jsx(XenIcon.Burger, { fontSize: "lg" }) }));
    if (!isExpanded) {
        return (_jsx(Tooltip, { label: label, position: "right", children: icon }));
    }
    return icon;
};
const Wrapper = ({ children }) => {
    return (_jsx(Group, { gap: "xxxs", h: "100%", pl: "0.45rem", pr: "md", py: { base: 'sm', xs: 'lg' }, wrap: "nowrap", children: children }));
};
